.PostFooter1{
    /* border: solid 2px; */
    width: 10%;
    height: 5vh;
    margin: 2PX;
    /* margin-left: 30%;
    margin-top: 2px;
    margin-bottom: none; */
}
.PostedOn{
    margin-left: 70%;
}
.PostHeader{
    /* border: solid 2px; */
    display: flex;
    width: 90%;
    margin-left: 30px;
    /* margin-top: 2px; */
    /* margin-bottom: none; */
}
.likesc{
    margin-left: 20px;
}
.PostCaption{
    margin-left: 20px;
}
.PostImage
{
    /* border: solid 2px; */
    width: 100%;
    /* height: 100vh; */
    /* margin-left: 30%;
    margin-top: 2px;
    margin-bottom: none; */
}
img {
    width: 100%;
    height: 400px;
    
}
.Posttop{
    border: solid  green 4px ;
    /* margin: 40px; */
    height: 200px;
    width:97%;
    /* margin-left: 30%; */
    margin: 5px;
    /* margin-bottom: none; */
    /* border: 1px solid; */
    border: 2px solid black;
    border-radius: 15px;
    padding: 0px;
    padding-bottom: 30px;
    box-shadow: 5px 10px 8px 10px #888888;
  }

.PostContainer{
    height: 30%;
    width: 40%;
    box-shadow: 5px 10px 8px 10px #888888;
    /* border: solid; */
    /* border-radius: solid black 20%; */
    border: 2px solid black;
  
  border-radius: 15px;
    margin-left: 30%;
    margin-top: 30px;
}
.imagetop{
    width: 60%;
    height: 100px;
    /* width: 50%;
    height: 100px;
    border: solid; */
    /* border:solid; */
    font-family: "Lucida Console", "Courier New", monospace;
    font-size: 30px;
    /* margin: 5px; */
    /* padding-bottom: 70px;
    margin: 50px;
    margin-top: 2px; */
    color:#888888
}
.PostAction{
    display: flex;
    /* border: solid; */
    margin-left: 70%;
    padding-top: 10px;
    width: 20%;
    font-size: 50px;
}
.myig{
    width: 10px;
    height: 10px;
}
.web{
    margin-top: none;
    float: right;
    border: solid 2px;
    height: 20%;
    width: 10%;
}
.Landingpage{
    display: flex;
    margin: 60px;
    border: solid green;
    float:left;
    width:90%;
    height: 90%;
}
.x10x{
    display: flex;

}
.imgland{
    height: 100vh;
    width: 65%;
}
button{
    border:2px solid green;
     border-radius:15px;
     font-size: 30px;


}